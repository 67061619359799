<template>
  <div class="evo-news-wrapper">
    <div :class="{ 'section-1-half-bg-1': !isPolicy, 'section-1-half-bg-2': isPolicy }"></div>
    <div class="evo-head text-center">
      <div class="evo-title evo-text-66">
        {{ $t('news.title') }}
      </div>
      <div class="evo-text-33 evo-mobile mx-auto">
        {{ $t('news.desc') }}
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import { newsroomMixin } from '@/mixins/newsroom.js';
export default {
  name: 'EvoMNewsroomIndexSection',
  mixins: [newsroomMixin]
};
</script>
<style lang="scss" scoped>
.evo-news-wrapper {
  background: $header_bg_color;
  position: relative;
  padding: 150px 0 64px;
  .evo-head {
    position: relative;
    height: calc(96vw - 150px);
    color: $header_color;
  }
  .evo-title {
    margin-bottom: 30px;
  }
}
@mixin section-1-half-bg {
  background-color: $nav_color;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 96vw;
  z-index: 0;
}
.section-1-half-bg-1 {
  @include section-1-half-bg;
  background-size: 182%;
  background-position: left top;
  background-image: url('~@/assets/header-bg.svg');
}
.section-1-half-bg-2 {
  @include section-1-half-bg;
  background-position: 95% 0;
  background-image: url('~@/assets/last-news-bg.svg');
}
</style>
