<template>
  <div class="evo-newsroom-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMNewsroomIndexSection />
    </template>
    <template v-else>
      <EvoNewsroomIndexSection />
    </template>
  </div>
</template>
<script>
import EvoNewsroomIndexSection from '@/components/newsroom_sections/index.vue';
import EvoMNewsroomIndexSection from '@/mobile/components/newsroom_sections/MIndex.vue';
export default {
  name: 'EvoNewsroomIndexView',
  components: {
    EvoNewsroomIndexSection,
    EvoMNewsroomIndexSection
  }
};
</script>
<style lang="scss" scoped>
.evo-newsroom-wrapper {
  position: relative;
  background: $header_bg_color;
}
</style>