<template>
  <div class="evo-news-wrapper evo-ph-176 evo-pv-60">
    <div
      :class="{
        'section-1-half-bg-1': !isPolicy,
        'section-1-half-bg-2': isPolicy
      }"
    ></div>

    <div class="evo-head">
      <div data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" class="evo-text-51 evo-mb-23 text-center">
        {{ $t('news.title') }}
      </div>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" class="evo-text-22 evo-mb-130 evo-opensans-regular text-center">
        {{ $t('news.desc') }}
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import { newsroomMixin } from '@/mixins/newsroom.js';
export default {
  name: 'EvoNewsroomIndexSection',
  mixins: [newsroomMixin]
};
</script>
<style lang="scss" scoped>
.evo-news-wrapper {
  background: $header_bg_color;
  position: relative;
  .evo-head {
    position: relative;
    height: calc(60vh - 60px);
    color: $header_color;
  }
}
@mixin section-1-half-bg {
  background-color: $nav_color;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 60vh;
  z-index: 0;
}
.section-1-half-bg-1 {
  @include section-1-half-bg;
  background-color: $nav_color;
  background-image: url('~@/assets/header-bg.svg') !important;
}
.section-1-half-bg-2 {
  @include section-1-half-bg;
  background-image: url('~@/assets/last-news-bg.svg') !important;
}
</style>
